import React from 'react';
import './Projects.css';

const Projects = () => {
  return (
    <section className="projects" id="projects">
      <h2>My Projects</h2>
      <div className="project-cards">
        {/* Example Project Cards */}
        <div className="project-card">
          <h3>Event Streaming Applicaion</h3>
          <p>Event streaming and block chain platform for entertainment and streaming</p>
        </div>
        <div className="project-card">
          <h3>Spiritual Application</h3>
          <p>A well framed web app for Spiritual services</p>
        </div>
      </div>
    </section>
  );
};

export default Projects;
