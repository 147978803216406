import React from 'react';
import { motion } from 'framer-motion';
import './Hero.css';


const Hero = () => {
  return (
      <section className="hero">
          <motion.div
              className="hero-content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
          >
              <div class="profile-container">
                  <img src="./rahulprofile.jpeg" alt="Profile Picture" class="profile-pic" />
              </div>
              <h1>Rahul Bellam</h1>
              <h1>Welcome to My Portfolio</h1>
              <p>I am a professional Full Stack developer and cloud enthusiast</p>
              <a href="#about" className="btn">Learn More</a>
          </motion.div>
      </section>
  );
};

export default Hero;
