import React from 'react';
import { motion } from 'framer-motion';
import './About.css';

const About = () => {
  return (
    <section className="about" id="about">
      <motion.div 
        className="about-content"
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 1 }}
      >
        <h2>About Me</h2>
        <p>As a passionate Frontend Developer with 2 years of hands-on experience, I have developed a strong foundation in crafting dynamic, responsive, and visually engaging user interfaces. My expertise spans the core technologies of web development, including HTML, CSS, and JavaScript, enabling me to effectively transform design concepts into seamless, functional code.

During my career, I have contributed to both short-term and long-term projects, honing my skills in various frontend frameworks such as Angular and ReactJS. While working with ReactJS, I also gained proficiency in Redux, a powerful state management tool that optimizes the management of complex application states.

With a keen eye for detail and a deep understanding of user-centric design, I am committed to delivering high-quality, scalable, and efficient web applications. I continuously strive to stay updated with the latest industry trends and best practices to ensure that my solutions are innovative and future-proof.</p>
        <h3 style={{color:''}}>Technical Skills</h3>
        <ul>
          <li>Languages: Java, JavaScript, Python </li>
          <li>Frameworks: ReactJs, Angular, nodeJs </li>
          <li>Deployment: Docker, Kubernetes</li>
          <li>Version Control: Github, Gitlab, AWS code commit</li>
          <li>AWS: Lambda, EC2, S3 </li>
        </ul>
      </motion.div>
    </section>
  );
};

export default About;
